<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员登录日志</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="账号" class="searchboxItem ci-full">
              <span class="itemLabel">账号:</span>
              <el-input
                v-model="seachData.mobile"
                type="text"
                size="small"
                clearable
                placeholder="请输入账号"
              />
            </div>
            <div title="登录结果" class="searchboxItem ci-full">
              <span class="itemLabel">登录结果:</span>
              <el-select v-model="seachData.status" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in loginResultList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="操作时间:" class="searchboxItem ci-full">
              <span class="itemLabel">登录时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="seachData.loginTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </div>
            <div class="btnBox">
              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="编号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column label="账号" align="left" prop="mobile" show-overflow-tooltip />
              <el-table-column label="姓名" align="left" prop="userName" show-overflow-tooltip />
              <el-table-column label="登录结果" align="left" show-overflow-tooltip prop="status">
                <template slot-scope="scope">
                  <span>
                    {{
                    $setDictionary("APPLOGINLOGSTATE", scope.row.status)}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="登录时间" align="left" show-overflow-tooltip prop="time">
                <template slot-scope="scope">{{scope.row.time | moment}}</template>
              </el-table-column>
              <el-table-column label="登录IP" align="left" show-overflow-tooltip prop="ip" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "OperationSupervision/loginLogAPP",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      seachData: {
        mobile: "",
        status: "",
        loginTime: []
      },
      loginResultList: []
    };
  },
  computed: {},
  created() {
    this.getLoginResultList();
    this.getData(-1);
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.seachData.mobile) {
        params.mobile = this.seachData.mobile;
      }
      if (this.seachData.status) {
        params.status = this.seachData.status;
      }

      if (this.seachData.loginTime && this.seachData.loginTime.length > 0) {
        params.startDate = this.seachData.loginTime[0];
        params.endDate = this.seachData.loginTime[1];
      }
      this.doFetch({
        url: "/logCenter/queryAppLoginLog",
        params,
        pageNum
      });
    },
    getLoginResultList() {
      const loginResultList = this.$setDictionary("APPLOGINLOGSTATE", "list");
      const list = [];
      list.push({
        value: "",
        label: "全部"
      });
      for (const key in loginResultList) {
        list.push({
          value: key,
          label: loginResultList[key]
        });
      }
      this.loginResultList = list;
    },
    // //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
